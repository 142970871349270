<template>
    <div class="sell-config">
        <ul class="info-card" v-if="RECVID && APIKEY">
            <li>                
                <van-row>
                    <van-col span="20"><h3>商户号</h3></van-col>
                    <van-col span="4"><van-tag round type="primary" class="testCopy" @click="clipboardText('.testCopy')" :data-clipboard-text="RECVID">复制</van-tag></van-col>
                </van-row>
                <van-row>
                    <van-col span="2">
                        <span class="iconfont icon-a-iconpay_huaban1fuben20" style="font-size:24px; vertical-align: middle; line-height:14px"></span>
                    </van-col>
                    <van-col span="20" style="word-wrap:break-word; line-height:18px">{{RECVID}}</van-col>
                </van-row>
            </li>
            <li>                
                <van-row>
                    <van-col span="20"><h3>秘钥</h3></van-col>
                    <van-col span="4"><van-tag round type="primary" class="testCopy" @click="clipboardText('.testCopy')" :data-clipboard-text="APIKEY">复制</van-tag></van-col>
                </van-row>
                <van-row>
                    <van-col span="2">
                        <span class="iconfont icon-a-iconpay_huaban1fuben57" style="font-size:24px; vertical-align: middle; line-height:14px"></span>
                    </van-col>
                    <van-col span="20" style="word-wrap:break-word; line-height:18px">{{APIKEY}}</van-col>
                </van-row>
            </li>
            <li>                
                <van-row>
                    <van-col span="20"><h3>充值姓名校验</h3></van-col>
                    <van-col span="4">
                        <van-switch v-model="sellRecheck" @click="changeSellRecheck" size="20px" inactive-color="#ccc" disabled />
                    </van-col>
                </van-row>
                <van-row>
                    <van-col span="24">
                        <p><span style="color:#e55">温馨提示：K宝姓名与平台姓名不符，会员充值会掉单</span></p>
                    </van-col>
                </van-row>
            </li>  
            <li>                
                <van-row>
                    <van-col span="20"><h3>代付姓名校验</h3></van-col>
                    <van-col span="4">
                        <van-switch v-model="drawcheck" @click="changeDrawcheck" size="20px" inactive-color="#ccc" disabled />
                    </van-col>
                </van-row>
            </li>   
        </ul> 
        <div class="white-list"> 
            <div v-if="whiteList.length">
                <div class="ip-card" v-for="(item, index) in whiteList" :key="index">
                    <div class="ip-item">
                        <div class="flex">
                            <div class="ip">{{item.IP}}</div>
                            <div class="btns">
                                <van-button size="mini" icon="delete-o" @click="delPayIpHandle(item)">删除</van-button>
                                <van-button size="mini" icon="records" @click="editPayIp(item)">编辑</van-button>
                            </div>
                        </div>
                        <div class="city"><span class="iconfont icon-a-payicon_iplocation"></span> {{item.IP_CITY}}</div>
                        <p class="remark">
                            {{item.REMARK || '无'}}
                        </p>
                    </div>
                </div>
            </div>
            <van-empty :image="require('@/assets/img/empty-image-default.png')" description="尚无IP白名单" v-else />
        </div>
        <div class="add-btn">
            <van-button block type="primary" @click="addPayIpVisible = true">添加IP</van-button>
        </div>

        <!-- 编辑IP模态 -->
        <van-popup v-model:show="editPayIpVisible" lock-scroll :close-on-click-overlay="false" round>
            <div class="popup">
                <div style="text-align:center; font-weight:blod; font-size:18px; line-height: 24px; padding: 10px; border-bottom: #eee 1px solid">编辑IP白名单</div>
                <van-form @submit="editPayIpSubmit">
                    <van-field
                        v-model="editForm.IP"
                        name="IP"
                        label="IP地址"
                        :rules="[{ required: true, message: 'IP地址不能为空' }]"
                    />
                    <van-field
                        v-model="editForm.REMARK"
                        name="REMARK"
                        label="备注"
                    />
                    <div class="btns">
                        <van-button block size="small" @click="editCancel">取消</van-button>
                        <van-button block type="primary" size="small" native-type="submit">完成</van-button>
                    </div>
                </van-form>
            </div>
        </van-popup> 

        <!-- 添加IP模态 -->
        <van-popup v-model:show="addPayIpVisible" lock-scroll :close-on-click-overlay="false" round>
            <div class="popup">
                <div style="text-align:center; font-weight:blod; font-size:18px; line-height: 24px; padding: 10px; border-bottom: #eee 1px solid">添加IP白名单</div>
                <van-form @submit="addPayIpSubmit">
                    <van-field
                        v-model="addForm.ip"
                        name="ip"
                        label="IP地址"
                        :rules="[{ required: true, message: 'IP地址不能为空' }]"
                    />
                    <van-field
                        v-model="addForm.remark"
                        name="remark"
                        label="备注"
                    />
                    <div class="btns">
                        <van-button block size="small" @click="addCancel">取消</van-button>
                        <van-button block type="primary" size="small" native-type="submit">完成</van-button>
                    </div>
                </van-form>
            </div>
        </van-popup>   
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { Dialog, Toast } from 'vant' 
import Clipboard from 'clipboard'
import { getPayIpList, delPayIp, addPayIp, changeRechcekType, changeDrawcheckType } from '@/api'
import { useStore } from 'vuex'
import { clipboardText } from '@/assets/js/common.js'

export default {
    name: 'SellConfig',
    setup() {
        const state = reactive({
            RECVID: null, 
            APIKEY: null,
            whiteList: [],
            addPayIpVisible: false,
            addForm: {ip: '', remark: ''},
            editPayIpVisible: false,
            editForm: {},
            passTypes: ['支付密码', '谷歌验证码'],
            sellPassType: 0,
            sellRecheck: true, // 充值姓名校验开关
            drawcheck: true // 代付姓名校验开关
        })

        const store = useStore()
        state.sellPassType = state.sellPassTypeOld = store.state.userInfo.SELL_PASSTYPE

        // 获取支付系统IP白名单列表
        // @returns [{ID, IP IP地址, IP_CITY IP翻译, CREATE_DATE 添加日期, REMARK 备注}]
        const getPayIpListHandle = () => {
            getPayIpList().then(res => {
                if (res.code === 200) {
                    state.whiteList = res.data
                } else {
                    console.log('获取白名单列表失败！');
                }
            })
        }

        // 编辑IP白名单
        const editPayIp = row => {
            state.editForm = row
            state.editPayIpVisible = true
        }
        const editPayIpSubmit = () => {
            const {ID, IP, REMARK} = state.editForm
            addPayIp({
                id: ID,
                ip: IP,
                remark: REMARK
            }).then(res => {
                state.editForm = {}
                state.editPayIpVisible = false
                if (res.code === 200) {
                    getPayIpListHandle()                         
                } else {
                    Toast.fail(res.message);
                }
            })
        }
        const editCancel = () => {
            state.editForm = {}
            state.editPayIpVisible = false
        }

        // 添加IP提交
        const addPayIpSubmit = params => {
            addPayIp(params).then(res => {
                state.addForm.ip = state.addForm.remark = ''
                state.addPayIpVisible = false
                if (res.code === 200) {
                    getPayIpListHandle()                         
                } else {
                    Toast.fail(res.message);
                }
            })
        }
        const addCancel = () => {
            state.addForm = {ip: '', remark: ''}
            state.addPayIpVisible = false
        }

        // 删除IP白名单
        const delPayIpHandle = row => {
            Dialog.confirm({
                title: '删除白名单',
                message: '确定要删除此条白名单记录？',
            })
            .then(() => {
                delPayIp({id: row.ID}).then(res => {
                    if (res.code === 200) {
                        getPayIpListHandle()                         
                    } else {
                        Toast.fail(res.message)
                    }
                })
            })
        }
        
        // 切换充值姓名校验开关
        let lockChangeSellRecheck = false
        const changeSellRecheck = e => {
            if (lockChangeSellRecheck) return
            lockChangeSellRecheck = true

            const checked = state.sellRecheck
            const message = `确定${checked ? '关闭' : '打开'}姓名验证？` 
            Dialog.confirm({
                title: '提醒',
                message
            }).then(() => {
                changeRechcekType({checktype: checked ? 0 : 1}).then(res => {
                    if (res.code === 200) {
                        state.sellRecheck = !state.sellRecheck
                    } else {
                        Toast.fail(res.message)
                    }
                    lockChangeSellRecheck = false
                }).catch(err => {
                    lockChangeSellRecheck = false
                })            
            }).catch(err => {
                lockChangeSellRecheck = false
            })
        }

        // 切换代付姓名校验开关
        let lockChangeDrawcheck = false
        const changeDrawcheck = e => {
            if (lockChangeDrawcheck) return
            lockChangeDrawcheck = true

            const checked = state.drawcheck
            const message = `确定${checked ? '关闭' : '打开'}代付姓名校验？` 
            Dialog.confirm({
                title: '提醒',
                message
            }).then(() => {
                changeDrawcheckType({checktype: checked ? 0 : 1}).then(res => {
                    if (res.code === 200) {
                        state.drawcheck = !state.drawcheck
                    } else {
                        Toast.fail(res.message)
                    }
                    lockChangeDrawcheck = false
                }).catch(err => {
                    lockChangeDrawcheck = false
                })            
            }).catch(err => {
                lockChangeDrawcheck = false
            })
        }

        return {
            ...toRefs(state),
            clipboardText,
            getPayIpListHandle,
            editPayIp,
            editPayIpSubmit,
            editCancel,
            addPayIpSubmit,
            addCancel,
            delPayIpHandle, 
            changeSellRecheck,
            changeDrawcheck
        }
    },
    beforeRouteEnter:function(to, from, next) {
        if (from.name !== 'User') next(from.path)
        const params = to.params || {}
        const { RECVID, APIKEY } = params
        if (RECVID && APIKEY) {
            next()
        } else {
            next(from.path)
        }
	},
    created:function(){
        const { RECVID, APIKEY, SELL_RECHECK, SELL_DRAWCHECK } = this.$route.params
        this.RECVID = RECVID
        this.APIKEY = APIKEY
        this.sellRecheck = SELL_RECHECK == 1 ? true : false
        this.drawcheck = SELL_DRAWCHECK == 1 ? true : false

        this.getPayIpListHandle()
	}
}
</script>

<style lang="scss" scoped>
.sell-config {
    padding: h(20) w(10);
    background-color: #eee;
    .info-card {
        background-color: #fff;
        border-radius: 8px;
        padding: 10px 15px;
        li {
            border-bottom: #eee 1px solid;
            padding: 10px 0;
            h3 {
                display: block;
                padding-bottom: 10px;
            }
            .testCopy {
                padding: 0 10px;
            }
            &:last-child{
                border: 0
            }
        }
    }
    .white-list {
        border: #f00 0px solid;
        padding: 15px 0;     
        .ip-card {
            padding: 10px 15px;
            border-radius: 10px;
            background-color: #fff;
            margin-bottom: 2px;
            .flex {
                display: flex;
            }
            .ip, .btns {
                line-height: 28px;
                flex: 1 1 auto;
                vertical-align: bottom;
            }
            .ip {
                font-size: 22px;
            }
            .city {
                font-size: 14px;
                .iconfont {
                    font-size: 20px;
                    vertical-align: middle;
                }
            }
            .btns {
                text-align: right;
                
                button {
                    margin-left: 10px;
                    padding: 0 10px;
                    vertical-align: middle;
                }
            }
            .remark {
                color: #999;
                border-top: #eee 1px solid;
                &::before {
                    content: '备注 ';
                    color: #333;
                }
            }
        }
    }
   
    .passtype-radio {
        border: #f00 0px solid;
        .item {
            display: inline-block;
            vertical-align: middle;
            color: #aaa;
            padding: 0 20px 0 10px;
            &::before {
                content: '';
                display: inline-block;
                width: 14px;
                height: 14px;
                border-width: 2px;
                border-style: solid;
                border-color: #ccc;
                border-radius: 50%;
                vertical-align: middle;
                margin-right: 5px;
            }
        }
        .item.checked{
            color: #1989fa;
        }
        .item.checked::before {
            border-width: 7px;
            width: 4px;
            height: 4px;
            border-color: #1989fa;
            color: #1989fa;
        }
    }
    .add-btn {
        
    }
       
}
.popup {
    width: 90vw;
    .btns {
        margin: 16px;
        text-align: center;
        button {
            display: inline-block;
            width: 100px;
            margin: 0 20px;
        }
    }
}

</style>
